.splash {
  height: 240px;
  overflow: hidden;
}
.splash img {
  position: relative;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  rotate: 1 1 1 -6deg;
  scale: 1.333;
}
.index-splash {
  height: 120px;
  overflow: hidden;
}
.index-splash img {
  position: relative;
  width: 100%;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transform: translate(-50%,-50%);
  rotate: 1 1 1 -6deg;
  scale: 1.333;
}
